<template>
  <BaseFormLayout
    :title="`${editMode ? 'Edit' : 'Add'} Passenger`"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #actions>
      <BaseSecondaryButton
        v-if="!editMode"
        id="employeeResetBtn"
        small
        class="mr-2 rounded-lg"
        @click="resetForm()"
        >Reset</BaseSecondaryButton
      >
      <BaseButton
        id="employeeSubmitBtn"
        small
        customClass="rounded-lg"
        :disabled="!isValid"
        @click="submitForm()"
        >Submit</BaseButton
      >
    </template>
    <template #content>
      <v-form ref="employeeForm" id="employeeForm" v-model="isValid">
        <v-row class="mx-2">
          <v-col cols="12" class="white rounded-lg">
            <v-card elevation="0">
              <v-card-title class="pa-2">
                <h4 class="text-body-1 font-weight-bold black--text">
                  Passenger Basic Details
                </h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="3">
                    <BaseTextInput
                      id="username"
                      name="username"
                      label="Passenger ID / Username"
                      v-model="employeeFormDetails.username"
                      :required="true"
                      :error-messages="
                        formErrors && formErrors.username
                          ? formErrors.username
                          : ''
                      "
                      @input="
                        formErrors && formErrors.username
                          ? delete formErrors.username
                          : ''
                      "
                    ></BaseTextInput>
                  </v-col>
                  <v-col cols="3">
                    <BaseTextInput
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      v-model="employeeFormDetails.first_name"
                      :required="true"
                      :error-messages="
                        formErrors && formErrors.first_name
                          ? formErrors.first_name
                          : ''
                      "
                      @input="
                        formErrors && formErrors.first_name
                          ? delete formErrors.first_name
                          : ''
                      "
                    ></BaseTextInput>
                  </v-col>
                  <v-col cols="3">
                    <BaseTextInput
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      v-model="employeeFormDetails.last_name"
                      :required="true"
                      :error-messages="
                        formErrors && formErrors.last_name
                          ? formErrors.last_name
                          : ''
                      "
                      @input="
                        formErrors && formErrors.last_name
                          ? delete formErrors.last_name
                          : ''
                      "
                    ></BaseTextInput>
                  </v-col>
                  <v-col cols="3">
                    <!-- <BasePhoneInput
                      id="contact_number"
                      name="contact_number"
                      label="Contact No"
                      v-model="employeeFormDetails.contact_number"
                      :required="true"
                      :error-messages="
                        formErrors && formErrors.contact_number
                          ? formErrors.contact_number
                          : ''
                      "
                      @input="
                        formErrors && formErrors.contact_number
                          ? delete formErrors.contact_number
                          : ''
                      "
                    ></BasePhoneInput> -->
                    <v-text-field
                      outlined
                      class="background-white rounded-lg remove-number-spinners"
                      type="number"
                      min="0"
                      step="01"
                      hide-details="auto"
                      dense
                      label="Contact number"
                      :error-messages="
                        formErrors && formErrors.contact_number
                          ? formErrors.contact_number
                          : ''
                      "
                      v-model="employeeFormDetails.contact_number"
                      @input="
                        formErrors && formErrors.contact_number
                          ? delete formErrors.contact_number
                          : ''
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <BasePasswordInput
                      id="password"
                      name="password"
                      label="Password"
                      :required="!editMode"
                      v-model="employeeFormDetails.password"
                      :error-messages="
                        formErrors && formErrors.password
                          ? formErrors.password
                          : ''
                      "
                      @input="
                        formErrors && formErrors.password
                          ? delete formErrors.password
                          : ''
                      "
                    ></BasePasswordInput>
                  </v-col>
                  <v-col cols="3">
                    <BasePasswordInput
                      id="confirm_password"
                      name="confirm_password"
                      label="Confirm Password"
                      :required="!editMode"
                      v-model="employeeFormDetails.confirm_password"
                      :error-messages="
                        formErrors && formErrors.confirm_password
                          ? formErrors.confirm_password
                          : ''
                      "
                      @input="
                        formErrors && formErrors.confirm_password
                          ? delete formErrors.confirm_password
                          : ''
                      "
                    ></BasePasswordInput>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mx-2 mt-6">
          <v-col cols="12" class="white rounded-lg">
            <v-card elevation="0">
              <v-card-title class="pa-2">
                <h4 class="text-body-1 font-weight-bold black--text">
                  Organization Details
                </h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="3">
                    <BaseSelect
                      id="company"
                      name="company"
                      label="Select Organization *"
                      v-model="employeeFormDetails.company"
                      :required="true"
                      :itemsList="companyList"
                      item-text="name"
                      item-value="value"
                      :rules="[(val) => !!val || 'Organization is required']"
                      :error-messages="
                        formErrors && formErrors.company
                          ? formErrors.company
                          : ''
                      "
                      @change="
                        formErrors && formErrors.company
                          ? delete formErrors.company
                          : '';
                        getCompanyAddressesList({
                          company_id: employeeFormDetails.company,
                        });
                        getCompanyShiftList({
                          company_id: employeeFormDetails.company,
                        });
                      "
                      :isClearable="false"
                    ></BaseSelect>
                    <!-- onCompanyChange($event); -->
                  </v-col>
                  <v-col cols="3">
                    <BaseSelect
                      id="company_address"
                      name="company_address"
                      ref="company_address"
                      label="Select Organization Address *"
                      v-model="employeeFormDetails.company_address"
                      :required="true"
                      :itemsList="companyAddressList"
                      :rules="[(val) => !!val || 'Organization Address is required']"
                      itemText="name"
                      itemValue="value"
                      :error-messages="
                        formErrors && formErrors.company_address
                          ? formErrors.company_address
                          : ''
                      "
                      @change="
                        formErrors && formErrors.company_address
                          ? delete formErrors.company_address
                          : ''
                      "
                      :isClearable="false"
                    ></BaseSelect>
                  </v-col>
                  <v-col cols="3">
                    <BaseSelect
                      id="company_shift"
                      name="company_shift"
                      ref="company_shift"
                      label="Select Shift Time *"
                      v-model="employeeFormDetails.company_shift"
                      :rules="[(val) => !!val || 'Shift Time is required']"
                      :required="true"
                      :itemsList="shiftList"
                      item-text="name"
                      item-value="value"
                      :error-messages="
                        formErrors && formErrors.company_shift
                          ? formErrors.company_shift
                          : ''
                      "
                      @change="
                        formErrors && formErrors.company_shift
                          ? delete formErrors.company_shift
                          : ''
                      "
                      :isClearable="false"
                    ></BaseSelect>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mx-2 mt-6">
          <v-col cols="12" class="white rounded-lg">
            <v-card elevation="0">
              <v-card-title class="pa-2">
                <h4 class="text-body-1 font-weight-bold black--text">
                  Addresses Details
                </h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                  <v-col cols="3">
                    <v-text-field
                      ref="home_address"
                      id="home_address"
                      name="home_address"
                      placeholder="Home Address *"
                      hide-details="auto"
                      v-model="employeeFormDetails.home_address"
                      :required="true"
                      dense
                      outlined
                      class="rounded-lg"
                      :rules="[(val) => !!val || 'Home Address is required']"
                      :error-messages="
                        formErrors && formErrors.home_address
                          ? formErrors.home_address
                          : ''
                      "
                      @input="
                        formErrors && formErrors.home_address
                          ? delete formErrors.home_address
                          : ''
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <BaseSelect
                      id="pickup_address"
                      name="pickup_address"
                      label="Preferred Pickup Address *"
                      v-model="employeeFormDetails.pickup_address"
                      :required="true"
                      :itemsList="pickupDropList"
                      :rules="[
                        (val) =>
                          !!val || 'Preferred Pickup Address is required',
                      ]"
                      item-text="name"
                      item-value="value"
                      :error-messages="
                        formErrors && formErrors.pickup_address
                          ? formErrors.pickup_address
                          : ''
                      "
                      @change="
                        formErrors && formErrors.pickup_address
                          ? delete formErrors.pickup_address
                          : ''
                      "
                      :isClearable="false"
                    ></BaseSelect>
                  </v-col>
                  <v-col cols="3">
                    <BaseSelect
                      id="drop_address"
                      name="drop_address"
                      label="Preferred Drop Address *"
                      v-model="employeeFormDetails.drop_address"
                      :required="true"
                      :itemsList="pickupDropList"
                      :rules="[
                        (val) => !!val || 'Preferred Drop Address is required',
                      ]"
                      item-text="name"
                      item-value="value"
                      :error-messages="
                        formErrors && formErrors.drop_address
                          ? formErrors.drop_address
                          : ''
                      "
                      @change="
                        formErrors && formErrors.drop_address
                          ? delete formErrors.drop_address
                          : ''
                      "
                      :isClearable="false"
                    ></BaseSelect>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </BaseFormLayout>
</template>

<script>
import { bus } from "@/main";
export default {
  props: {
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      employeeFormDetails: {},
      companyList: [],
      companyAddressList: [],
      shiftList: [],
      home_coordinates: {},
      pickupDropList: [],
    };
  },
  methods: {
    getPickupDropStopsList(params) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.pickup_drop_stops.getPickupDropStopsList(params).then((res) => {
        this.pickupDropList = res.data.map((obj) => {
          return {
            name: obj.location_name,
            value: obj.id,
          };
        });
      });
    },
    onCompanyChange(event) {
      if (event) {
        this.employeeFormDetails.company_address = null;
        this.employeeFormDetails.shift_time = null;
        // this.$refs.company_address.reset();
        this.$refs.company_address.resetValidation();
        // this.$refs.shift_time.reset();
        this.$refs.shift_time.resetValidation();
      }
    },
    getEmployeeDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.employees
        .getEmployeeObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.employeeFormDetails = res.data;
          this.home_coordinates = res.data.home_coordinates;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    getCompanyList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company.getCompanyList(params).then((res) => {
        this.companyList = res.data.map((obj) => {
          return {
            name: obj.name,
            value: obj.company_id,
          };
        });
      });
    },
    getCompanyAddressesList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company_addresses
        .getCompanyAddressesList(params)
        .then((res) => {
          this.companyAddressList = res.data.map((obj) => {
            return {
              name: obj.location_name,
              value: obj.id,
            };
          });
        });
    },
    getCompanyShiftList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company_shifts.getCompanyShiftList(params).then((res) => {
        this.shiftList = res.data.map((obj) => {
          return {
            name: obj.shift_name,
            value: obj.shift_id,
          };
        });
      });
    },
    setAutoComplete() {
      const interval = setInterval(async () => {
        if (this.$refs.home_address && window.google && window.google.maps) {
          clearInterval(interval);

          let input = document.getElementById("home_address");

          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          this.autocomplete.setComponentRestrictions({
            country: ["BA"],
          });

          this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();
            let lat = place.geometry.location.lat().toFixed(6);
            let lon = place.geometry.location.lng().toFixed(6);
            setTimeout(() => {
              this.employeeFormDetails.home_address =
                place.name + ", " + place.formatted_address;
              this.home_coordinates.latitude = lat;
              this.home_coordinates.longitude = lon;
            }, 100);
          });
        }
      }, 100);
    },
    resetForm() {
      this.$refs.employeeForm.reset();
      this.$refs.employeeForm.resetValidation();
    },
    submitForm() {
      if (!this.$refs.employeeForm.validate()) {
        bus.$emit("showToastMessage", {
          message: "Please correct all the errors to submit the form!",
          color: "error",
        });
        return;
      }
      let data = {
        ...this.employeeFormDetails,
        home_coordinates: this.home_coordinates,
      };
      bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.$api.employees
          .createEmployee(data)
          .then((res) => {
            bus.$emit("showLoader", false);

            bus.$emit("showToastMessage", {
              message: "Passenger Created!",
              color: "success",
            });
            this.resetForm();
            this.$router.back();
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      } else {
        this.$api.employees
          .updateEmployeesObject({
            id: this.employeeFormDetails.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showToastMessage", {
              message: "Passenger updated!",
              color: "success",
            });
            this.resetForm();
            this.$router.back();
          })
          .catch((err) => {
            this.formErrors = err.data;
          });
      }
    },
  },
  mounted() {
    this.setAutoComplete();
    this.getCompanyList();
    this.getPickupDropStopsList();
    if (this.$route.params.id) {
      this.getEmployeeDetails(this.$route.params.id);
      this.getCompanyAddressesList({
        company_id: this.employeeFormDetails.company,
      });
      this.getCompanyShiftList({
        company_id: this.employeeFormDetails.company,
      });
    }
  },
};
</script>

<style>
</style>