<template>
  <BaseDialog
    v-model="openCompanyLocationForm"
    :title="`${editMode ? 'Edit' : 'Add'} Organization Location`"
    width="90vh"
    @closeDialog="resetForm()"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="companyLocationForm"
        id="companyLocationForm"
        name="companyLocationForm"
      >
        <v-row>
          <!-- <v-col cols="6" lg="6">
            <BaseTextInput
              id="location_id"
              name="location_id"
              v-model="companyLocationForm.location_id"
              :required="true"
              label="Location ID"
              :error-messages="
                formErrors && formErrors.location_id
                  ? formErrors.location_id
                  : ''
              "
              @input="
                formErrors && formErrors.location_id
                  ? delete formErrors.location_id
                  : ''
              "
            ></BaseTextInput>
          </v-col> -->
          <v-col cols="6" lg="6">
            <BaseTextInput
              id="location_name"
              name="location_name"
              v-model="companyLocationForm.location_name"
              :required="true"
              label="Location Name"
              :error-messages="
                formErrors && formErrors.location_name
                  ? formErrors.location_name
                  : ''
              "
              @input="
                formErrors && formErrors.location_name
                  ? delete formErrors.location_name
                  : ''
              "
            ></BaseTextInput>
          </v-col>
          <v-col cols="6" lg="6">
            <BaseSelect
              id="company"
              name="company"
              v-model="companyLocationForm.company"
              :required="true"
              label="Select Organization *"
              :itemsList="companyList"
              itemText="name"
              itemValue="value"
              :rules="[(val) => !!val || 'Organization is required']"
              :error-messages="
                formErrors && formErrors.company ? formErrors.company : ''
              "
              @change="
                formErrors && formErrors.company
                  ? delete formErrors.company
                  : '';
                getCompanyShiftList({
                  company_id: companyLocationForm.company,
                });
              "
            ></BaseSelect>
          </v-col>
          <v-col cols="12" lg="12">
            <v-text-field
              ref="address"
              id="address"
              name="address"
              placeholder="Location Address *"
              hide-details="auto"
              v-model="companyLocationForm.address"
              :required="true"
              dense
              outlined
              class="rounded-lg"
              :rules="[(val) => !!val || 'Location Address is required']"
              :error-messages="
                formErrors && formErrors.address ? formErrors.address : ''
              "
              @input="
                formErrors && formErrors.address
                  ? delete formErrors.address
                  : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="6" lg="6">
            <BaseSelect
              id="shift"
              name="shift"
              v-model="companyLocationForm.shifts"
              :required="true"
              :multiple="true"
              label="Select Shift(s) *"
              :itemsList="shiftList"
              itemText="name"
              itemValue="value"
              :rules="[(val) => !!val || 'Shift(s) is required']"
              :error-messages="
                formErrors && formErrors.shifts ? formErrors.shifts : ''
              "
              @change="
                formErrors && formErrors.shifts ? delete formErrors.shifts : ''
              "
            ></BaseSelect>
          </v-col>
          <v-col cols="6" lg="6">
            <BaseTextInput
              id="contact_person"
              name="contact_person"
              v-model="companyLocationForm.contact_person"
              :required="true"
              label="Contact Person"
              :error-messages="
                formErrors && formErrors.contact_person
                  ? formErrors.contact_person
                  : ''
              "
              @input="
                formErrors && formErrors.contact_person
                  ? delete formErrors.contact_person
                  : ''
              "
            ></BaseTextInput>
          </v-col>
          <v-col cols="6" lg="6">
            <!-- <BasePhoneInput
              id="contact_number"
              name="contact_number"
              v-model="companyLocationForm.contact_number"
              :required="true"
              label="Contact No."
              :error-messages="
                formErrors && formErrors.contact_number
                  ? formErrors.contact_number
                  : ''
              "
              @input="
                formErrors && formErrors.contact_number
                  ? delete formErrors.contact_number
                  : ''
              "
            ></BasePhoneInput> -->
            <v-text-field
              outlined
              class="background-white rounded-lg remove-number-spinners"
              type="number"
              min="0"
              step="01"
              hide-details="auto"
              dense
              label="Contact number"
              :error-messages="
                formErrors && formErrors.contact_number
                  ? formErrors.contact_number
                  : ''
              "
              v-model="companyLocationForm.contact_number"
              @input="
                formErrors && formErrors.contact_number
                  ? delete formErrors.contact_number
                  : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="6" lg="6">
            <BaseEmailInput
              id="contact_email"
              name="contact_email"
              v-model="companyLocationForm.contact_email"
              :required="true"
              label="Contact Email"
              :error-messages="
                formErrors && formErrors.contact_email
                  ? formErrors.contact_email
                  : ''
              "
              @input="
                formErrors && formErrors.contact_email
                  ? delete formErrors.contact_email
                  : ''
              "
            ></BaseEmailInput>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <BaseSecondaryButton
        v-if="!editMode"
        customClass="rounded-lg"
        small
        id="companyLocationResetBtn"
        @click="resetForm()"
        >Reset</BaseSecondaryButton
      >
      <BaseButton
        :disabled="!isValid"
        customClass="rounded-lg"
        small
        id="companyLocationSubmitBtn"
        @click="submitForm()"
        >Submit</BaseButton
      >
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
export default {
  props: {
    value: Boolean,
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      companyLocationForm: {},
      formErrors: {},
      isValid: true,
      companyList: [],
      coordinates: {},
      shiftList: [],
    };
  },
  watch: {
    openCompanyLocationForm(val) {
      if (val) {
        this.setAutoComplete();
        this.getCompanyList();
      }
    },
  },
  computed: {
    openCompanyLocationForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getCompanyAddressesObject(id = null) {
      bus.$emit("showLoader", true);
      this.$api.company_addresses
        .getCompanyAddressesObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.companyLocationForm = res.data;
          this.coordinates = res.data.coordinates;
          if (this.companyLocationForm.company) {
            this.getCompanyShiftList({
              company_id: this.companyLocationForm.company,
            });
          }
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    setAutoComplete() {
      const interval = setInterval(async () => {
        if (this.$refs.address && window.google && window.google.maps) {
          clearInterval(interval);

          let input = document.getElementById("address");

          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          this.autocomplete.setComponentRestrictions({
            country: ["BA"],
          });

          this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();
            let lat = place.geometry.location.lat().toFixed(6);
            let lon = place.geometry.location.lng().toFixed(6);
            setTimeout(() => {
              this.companyLocationForm.address =
                place.name + ", " + place.formatted_address;
              this.coordinates.latitude = lat;
              this.coordinates.longitude = lon;
            }, 100);
          });
        }
      }, 100);
    },

    getCompanyList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company.getCompanyList(params).then((res) => {
        this.companyList = res.data.map((obj) => {
          return {
            name: obj.name,
            value: obj.company_id,
          };
        });
      });
    },

    getCompanyShiftList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company_shifts.getCompanyShiftList(params).then((res) => {
        this.shiftList = res.data.map((obj) => {
          return {
            name: obj.shift_name,
            value: obj.id,
          };
        });
      });
    },

    resetForm() {
      this.formErrors = {
        non_field_errors: [],
      };
      this.companyLocationForm = {};
      this.$refs.companyLocationForm.reset();
      this.$refs.companyLocationForm.resetValidation();
    },

    submitForm() {
      let data = { ...this.companyLocationForm, coordinates: this.coordinates };
      if (!this.editMode) {
        this.$api.company_addresses
          .createCompanyAddresses(data)
          .then((res) => {
            bus.$emit("showToastMessage", {
              message: "Organization Address Created!",
              color: "success",
            });
            this.openCompanyLocationForm = false;
            this.$emit("refreshList");
            this.resetForm();
          })
          .catch((err) => {
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      } else {
        this.$api.company_addresses
          .updateCompanyAddressesObject({
            id: this.companyLocationForm.id,
            data: data,
          })
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Organization Address Updated!",
              color: "success",
            });
            this.openCompanyLocationForm = false;
            this.$emit("refreshList");
            this.resetForm();
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            if (err.data.non_field_errors) {
              this.formErrors.non_field_errors = err.data.non_field_errors;
            }
          });
      }
    },
  },
};
</script>

<style>
</style>