<template>
  <BaseFormLayout :title="`${editMode ? 'Edit' : 'Add'} Organization`">
    <template #content>
      <v-stepper v-model="currentStep" flat>
        <!-- <v-stepper-header class="elevation-0 light_background">
          <v-row>
            <v-col>
              <v-stepper-step :complete="currentStep > 1" step="1">
                <div class="d-flex flex-column">
                  <h4 class="text-body-1 font-weight-normal primary--text">
                    Organization Info
                  </h4>
                </div>
              </v-stepper-step>
            </v-col>


            <v-col>
              <v-stepper-step :complete="currentStep > 2" step="2">
                <div class="d-flex flex-column">
                  <h4 class="text-body-1 font-weight-normal primary--text">
                    Organization Shift Info
                  </h4>
                </div>
              </v-stepper-step>
            </v-col>


            <v-col>
              <v-stepper-step step="3">
                <div class="d-flex flex-column">
                  <h4 class="text-body-1 font-weight-normal primary--text">
                    Organization Location Details
                  </h4>
                </div></v-stepper-step
              >
            </v-col>
          </v-row>
        </v-stepper-header> -->

        <v-stepper-items class="">
          <v-stepper-content step="1" class="rounded-lg elevation-1 pa-0 pt-2">
            <v-card elevation="0" class="rounded-lg">
              <v-card-title class="">
                <h4 class="text-body-1 font-weight-bold black--text">
                  Organization Info
                </h4>
              </v-card-title>
              <v-card-subtitle>
                <h5 class="text-caption light_grey--text">
                  All field(s) with (*) are mandatory
                </h5>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-text>
                <v-form
                  v-model="isValidStep1"
                  ref="companyFormStep1"
                  id="companyFormStep1"
                  name="companyFormStep1"
                >
                  <v-row>
                    <v-col cols="3">
                      <BaseTextInput
                        id="company_id"
                        name="company_id"
                        label="Organization ID"
                        :required="true"
                        v-model="companyFormDetails.company_id"
                        :error-messages="
                          formErrorsStep1 && formErrorsStep1.company_id
                            ? formErrorsStep1.company_id
                            : ''
                        "
                        @input="
                          formErrorsStep1 && formErrorsStep1.company_id
                            ? delete formErrorsStep1.company_id
                            : ''
                        "
                      ></BaseTextInput>
                    </v-col>
                    <v-col cols="3">
                      <BaseTextInput
                        id="name"
                        name="name"
                        label="Organization Name"
                        :required="true"
                        v-model="companyFormDetails.name"
                        :error-messages="
                          formErrorsStep1 && formErrorsStep1.name
                            ? formErrorsStep1.name
                            : ''
                        "
                        @input="
                          formErrorsStep1 && formErrorsStep1.name
                            ? delete formErrorsStep1.name
                            : ''
                        "
                      ></BaseTextInput>
                    </v-col>
                    <v-col cols="3">
                      <BaseSelect
                        id="project"
                        name="project"
                        label="Select Project *"
                        :required="true"
                        :rules="[(val) => !!val || 'Project is required']"
                        :itemsList="projectList"
                        itemText="name"
                        itemValue="value"
                        v-model="companyFormDetails.project"
                        :error-messages="
                          formErrorsStep1 && formErrorsStep1.project
                            ? formErrorsStep1.project
                            : ''
                        "
                        @change="
                          formErrorsStep1 && formErrorsStep1.project
                            ? delete formErrorsStep1.project
                            : ''
                        "
                      ></BaseSelect>
                    </v-col>
                    <v-col cols="3">
                      <!-- <BasePhoneInput
                        id="contact_number"
                        name="contact_number"
                        label="Contact No."
                        v-model="companyFormDetails.contact_number"
                        :error-messages="
                          formErrorsStep1 && formErrorsStep1.contact_number
                            ? formErrorsStep1.contact_number
                            : ''
                        "
                        @input="
                          formErrorsStep1 && formErrorsStep1.contact_number
                            ? delete formErrorsStep1.contact_number
                            : ''
                        "
                      ></BasePhoneInput> -->
                      <v-text-field
                        outlined
                        class="background-white rounded-lg remove-number-spinners"
                        type="number"
                        min="0"
                        step="01"
                        hide-details="auto"
                        dense
                        label="Contact number"
                        :error-messages="
                          formErrorsStep1 && formErrorsStep1.contact_number
                            ? formErrorsStep1.contact_number
                            : ''
                        "
                        v-model="companyFormDetails.contact_number"
                        @input="
                          formErrorsStep1 && formErrorsStep1.contact_number
                            ? delete formErrorsStep1.contact_number
                            : ''
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <BaseEmailInput
                        id="contact_email"
                        name="contact_email"
                        label="Email"
                        :required="true"
                        v-model="companyFormDetails.contact_email"
                        :error-messages="
                          formErrorsStep1 && formErrorsStep1.contact_email
                            ? formErrorsStep1.contact_email
                            : ''
                        "
                        @input="
                          formErrorsStep1 && formErrorsStep1.contact_email
                            ? delete formErrorsStep1.contact_email
                            : ''
                        "
                      ></BaseEmailInput>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider class="mt-5"></v-divider>
              <v-card-actions class="d-flex justify-end ma-3">
                <BaseSecondaryButton
                  id="resetBtn"
                  customClass="rounded-lg "
                  small
                  @click="resetCompanyForm()"
                  >Reset</BaseSecondaryButton
                >
                <BaseButton
                  id="submitBtn"
                  customClass="rounded-lg "
                  :disabled="!isValidStep1"
                  small
                  @click="submitCompany()"
                  >Submit</BaseButton
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2" class="rounded-lg elevation-1 pa-0 pt-2">
            <v-card elevation="0" class="rounded-lg">
              <v-card-title>
                <h4 class="text-body-1 font-weight-bold black--text">
                  Organization Shifts
                </h4>
              </v-card-title>
              <v-card-subtitle>
                <h5 class="text-caption light_grey--text">
                  All field(s) with (*) are mandatory
                </h5>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-text>
                <v-form
                  v-model="isValidStep2"
                  ref="companyFormStep2"
                  id="companyFormStep2"
                  name="companyFormStep2"
                >
                  <v-row>
                    <div
                      class="squareTitle d-flex justify-center align-center text-center ml-3"
                    >
                      <h4
                        class="text-body-2 font-weight-bold light_grey--text mt-1"
                      >
                        #
                      </h4>
                    </div>
                    <v-col cols="3" class="d-flex justify-start align-center">
                      <h4
                        class="text-caption font-weight-bold light_grey--text text-uppercase"
                      >
                        Shift ID
                      </h4>
                    </v-col>
                    <v-col
                      cols="3"
                      class="d-flex justify-start align-center text-uppercase"
                    >
                      <h4
                        class="text-caption font-weight-bold light_grey--text"
                      >
                        Shift Name
                      </h4>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-start align-center text-uppercase"
                    >
                      <h4
                        class="text-caption font-weight-bold light_grey--text"
                      >
                        Shift Start
                      </h4>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex justify-start align-center text-uppercase"
                    >
                      <h4
                        class="text-caption font-weight-bold light_grey--text"
                      >
                        Shift End
                      </h4>
                    </v-col>
                    <v-col
                      cols="1"
                      class="d-flex justify-start align-center text-uppercase"
                    >
                      <h4
                        class="text-caption font-weight-bold light_grey--text"
                      >
                        Actions
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      v-for="(shift, index) in companyShiftDetails.shifts"
                      :key="index"
                      class="d-flex justify-center"
                    >
                      <div
                        class="square d-flex justify-center align-center font-weight-bold mr-2"
                      >
                        {{ index + 1 }}.
                      </div>
                      <v-row>
                        <v-col cols="3">
                          <BaseTextInput
                            id="shift_id"
                            name="shift_id"
                            v-model="shift.shift_id"
                            :error-messages="
                              formErrorsStep2 && formErrorsStep2.shift_id
                                ? formErrorsStep2.shift_id
                                : ''
                            "
                            @input="
                              formErrorsStep2 && formErrorsStep2.shift_id
                                ? formErrorsStep2.shift_id
                                : ''
                            "
                            :required="true"
                            label="Shift ID"
                          ></BaseTextInput>
                        </v-col>

                        <v-col cols="3">
                          <BaseTextInput
                            id="shift_name"
                            name="shift_name"
                            v-model="shift.shift_name"
                            :error-messages="
                              formErrorsStep2 && formErrorsStep2.shift_name
                                ? formErrorsStep2.shift_name
                                : ''
                            "
                            @input="
                              formErrorsStep2 && formErrorsStep2.shift_name
                                ? formErrorsStep2.shift_name
                                : ''
                            "
                            :required="true"
                            label="Shift Name"
                          ></BaseTextInput>
                        </v-col>

                        <v-col cols="2">
                          <BaseTimeInput
                            id="from_time"
                            name="from_time"
                            v-model="shift.from_time"
                            :error-messages="
                              formErrorsStep2 && formErrorsStep2.from_time
                                ? formErrorsStep2.from_time
                                : ''
                            "
                            @input="
                              formErrorsStep2 && formErrorsStep2.from_time
                                ? formErrorsStep2.from_time
                                : ''
                            "
                            :required="true"
                            label="Shift Start"
                          ></BaseTimeInput>
                        </v-col>
                        <v-col cols="2">
                          <BaseTimeInput
                            id="to_time"
                            name="to_time"
                            v-model="shift.to_time"
                            label="Shift End"
                            :error-messages="
                              formErrorsStep2 && formErrorsStep2.to_time
                                ? formErrorsStep2.to_time
                                : ''
                            "
                            :required="true"
                            @input="
                              formErrorsStep2 && formErrorsStep2.to_time
                                ? formErrorsStep2.to_time
                                : ''
                            "
                          ></BaseTimeInput>
                        </v-col>
                        <v-col class="d-flex justify-center align-center">
                          <BaseSecondaryButton
                            id="addShift"
                            customClass="rounded-lg mr-2"
                            @click="addShift"
                            small
                            >Add</BaseSecondaryButton
                          >
                          <BaseSecondaryButton
                            :disabled="companyShiftDetails.shifts.length == 1"
                            id="removeShift"
                            customClass="red--text rounded-lg"
                            outlined
                            small
                            @click="removeShift(index)"
                            >Remove</BaseSecondaryButton
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider class="mt-5"></v-divider>
              <v-card-actions class="d-flex justify-end ma-3">
                <BaseSecondaryButton id="backStep2" @click="previousStep" small
                  >Back</BaseSecondaryButton
                >
                <BaseButton
                  id="nextStep2Btn"
                  customClass="rounded-lg"
                  :disabled="!isValidStep2"
                  @click="submitCompanyShift()"
                  small
                  >Next</BaseButton
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3" class="rounded-lg elevation-1 pa-0 pt-2">
            <v-row class="ma-0 pa-0" no-gutters>
              <v-col cols="12">
                <v-card elevation="0" class="rounded-lg">
                  <v-card-title class="">
                    <h4 class="text-body-1 font-weight-bold black--text">
                      Organization Location Info
                    </h4>
                  </v-card-title>
                  <v-card-subtitle>
                    <h5 class="text-caption light_grey--text">
                      All field(s) with (*) are mandatory
                    </h5>
                  </v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-form
                      v-model="isValidStep3"
                      ref="companyFormStep3"
                      id="companyFormStep3"
                      name="companyFormStep3"
                    >
                      <v-row>
                        <v-col cols="3">
                          <BaseTextInput
                            id="location_id"
                            name="location_id"
                            label="Location ID"
                            :required="true"
                            v-model="companyLocationDetails.location_id"
                            :error-messages="
                              formErrorsStep3 && formErrorsStep3.location_id
                                ? formErrorsStep3.location_id
                                : ''
                            "
                            @input="
                              formErrorsStep3 && formErrorsStep3.location_id
                                ? delete formErrorsStep3.location_id
                                : ''
                            "
                          ></BaseTextInput>
                        </v-col>
                        <v-col cols="3">
                          <BaseTextInput
                            id="location_name"
                            name="location_name"
                            label="Location Name"
                            :required="true"
                            v-model="companyLocationDetails.location_name"
                            :error-messages="
                              formErrorsStep3 && formErrorsStep3.location_name
                                ? formErrorsStep3.location_name
                                : ''
                            "
                            @input="
                              formErrorsStep3 && formErrorsStep3.location_name
                                ? delete formErrorsStep3.location_name
                                : ''
                            "
                          ></BaseTextInput>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            ref="address"
                            id="address"
                            name="address"
                            placeholder="Location Address *"
                            hide-details="auto"
                            v-model="companyLocationDetails.address"
                            :required="true"
                            dense
                            outlined
                            class="rounded-lg"
                            :rules="[
                              (val) => !!val || 'Location Address is required',
                            ]"
                            :error-messages="
                              formErrorsStep3 && formErrorsStep3.address
                                ? formErrorsStep3.address
                                : ''
                            "
                            @input="
                              formErrorsStep3 && formErrorsStep3.address
                                ? delete formErrorsStep3.address
                                : ''
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <BaseSelect
                            id="shift"
                            name="shift"
                            v-model="companyLocationDetails.shifts"
                            :required="true"
                            :multiple="true"
                            label="Select Shift(s) *"
                            :itemsList="shiftList"
                            itemText="name"
                            itemValue="value"
                            :rules="[(val) => !!val || 'Shift(s) is required']"
                            :error-messages="
                              formErrorsStep3 && formErrorsStep3.shifts
                                ? formErrorsStep3.shifts
                                : ''
                            "
                            @change="
                              formErrorsStep3 && formErrorsStep3.shifts
                                ? delete formErrorsStep3.shifts
                                : ''
                            "
                          ></BaseSelect>
                        </v-col>
                        <v-col cols="3">
                          <!-- <BasePhoneInput
                        id="contact_number"
                        name="contact_number"
                        label="Contact No."
                        v-model="companyFormDetails.contact_number"
                        :error-messages="
                          formErrorsStep1 && formErrorsStep1.contact_number
                            ? formErrorsStep1.contact_number
                            : ''
                        "
                        @input="
                          formErrorsStep1 && formErrorsStep1.contact_number
                            ? delete formErrorsStep1.contact_number
                            : ''
                        "
                      ></BasePhoneInput> -->
                          <v-text-field
                            outlined
                            class="background-white rounded-lg remove-number-spinners"
                            type="number"
                            min="0"
                            step="01"
                            hide-details="auto"
                            dense
                            label="Contact number"
                            :error-messages="
                              formErrorsStep3 && formErrorsStep3.contact_number
                                ? formErrorsStep3.contact_number
                                : ''
                            "
                            v-model="companyLocationDetails.contact_number"
                            @input="
                              formErrorsStep3 && formErrorsStep3.contact_number
                                ? delete formErrorsStep3.contact_number
                                : ''
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <BaseTextInput
                            id="companyLocContactPerson"
                            name="companyLocContactPerson"
                            label="Contact Person"
                            :required="true"
                            :error-messages="
                              formErrorsStep3 && formErrorsStep3.contact_number
                                ? formErrorsStep3.contact_number
                                : ''
                            "
                            v-model="companyLocationDetails.contact_number"
                            @input="
                              formErrorsStep3 && formErrorsStep3.contact_number
                                ? delete formErrorsStep3.contact_number
                                : ''
                            "
                          ></BaseTextInput>
                        </v-col>
                        <v-col cols="3">
                          <BaseEmailInput
                            id="contact_email"
                            name="contact_email"
                            label="Email"
                            :required="true"
                            v-model="companyLocationDetails.contact_email"
                            :error-messages="
                              formErrorsStep3 && formErrorsStep3.contact_email
                                ? formErrorsStep3.contact_email
                                : ''
                            "
                            @input="
                              formErrorsStep3 && formErrorsStep3.contact_email
                                ? delete formErrorsStep3.contact_email
                                : ''
                            "
                          ></BaseEmailInput>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="d-flex justify-end mr-4 mt-1">
                    <BaseButton
                      id="companyLocationAdd"
                      customClass="rounded-lg"
                      small
                    >
                      Add
                    </BaseButton>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-card
              elevation="0"
              class="rounded-lg"
              v-if="companyLocationList && companyLocationList.length > 0"
            >
              <v-card-text>
                <v-row class="ma-0 pa-0">
                  <v-col cols="12">
                    <AgGridVue
                      @grid-ready="onGridReady"
                      :grid-options="gridOptions"
                      :column-defs="headers"
                      :context="context"
                      :row-data="companyLocationList"
                      style="width: 100%; height: 450px"
                      class="ag-theme-alpine"
                    >
                    </AgGridVue>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </BaseFormLayout>
</template>

<script>
import { bus } from "@/main";
import { AgGridVue } from "ag-grid-vue";
export default {
  props: {
    editMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      totalItems: 0,
      pageNo: 1,
      totalCount: 0,

      gridApi: null,
      columnApi: null,
      gridOptions: {
        onGridSizeChanged: () => {
          if (this.selectedHeaders <= 6) {
            this.gridOptions.api.sizeColumnsToFit();
          } else {
            setTimeout(() => {
              this.autoSizeAll();
            }, 200);
          }
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },

      currentStep: 1,
      isValidStep1: true,
      isValidStep2: true,
      isValidStep3: true,
      formErrorsStep1: {},
      formErrorsStep2: {},
      formErrorsStep3: {},
      companyFormDetails: {},
      companyLocationDetails: {},
      companyShiftDetails: {
        shifts: [{}],
      },
      projectList: [],
      shiftList: [],
      companyLocationList: [],
      companyID: null,
      headers: [
        {
          headerName: "Location ID",
          field: "location_id",
        },
        {
          headerName: "Location Name",
          field: "location_name",
        },
        {
          headerName: "Address",
          field: "address",
        },
        {
          headerName: "Coordinate (Latitude & Longitude)",
          valueGetter: function (params) {
            return (
              params.data.coordinates.latitude +
              ", " +
              params.data.coordinates.longitude
            );
          },
        },
        {
          headerName: "Organization",
          field: "company_name",
        },
        {
          headerName: "No. of Persons(s)",
          field: "no_of_employees",
        },
        {
          headerName: "Total Shifts",
          field: "company_shifts_count",
        },
        {
          headerName: "Contact Person",
          field: "contact_person",
        },
        {
          headerName: "Contact No",
          field: "contact_number",
        },
        {
          headerName: "Contact Email",
          field: "contact_email",
        },
      ],
      coordinates: {},
    };
  },
  watch: {
    currentStep(val) {
      if (val) {
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.headerSelected = value;
    },
    // step 1 methods while adding company
    resetCompanyForm() {
      this.$refs.companyFormStep1.reset();
      this.$refs.companyFormStep1.resetValidation();
    },
    getProjectsList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.projects.getProjectList(params).then((res) => {
        this.projectList = res.data.map((obj) => {
          return {
            name: obj.project_name,
            value: obj.project_id,
          };
        });
      });
    },
    submitCompany() {
      if (!this.$refs.companyFormStep1.validate()) {
        bus.$emit("showToastMessage", {
          message: "Please correct all the errors to submit the form!",
          color: "error",
        });
        return;
      }
      let data = { ...this.companyFormDetails };
      bus.$emit("showLoader", true);
      this.$api.company
        .createCompany(data)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Organization Created!",
            color: "success",
          });
          // localStorage.setItem("companyID", res.data.id);
          // this.companyID = res.data.id;
          this.$router.back();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          if (err) {
            this.formErrorsStep1 = err.data;
          }
        });
    },
    nextStep1() {
      this.currentStep++;
    },
    // step 2 methods
    scrollToBottom() {
      setTimeout(() => {
        let elem = document.getElementById("companyFormStep2");
        elem.scrollIntoView({ block: "end", behavior: "smooth" });
      }, 100);
    },
    submitCompanyShift() {
      if (!this.$refs.companyFormStep2.validate()) {
        bus.$emit("showToastMessage", {
          message: "Please correct all the errors to submit the form!",
          color: "error",
        });
        return;
      }
      let data = {
        ...this.companyShiftDetails.shifts,
        company: this.companyID,
      };
      bus.$emit("showLoader", true);
      this.$api.company_shifts
        .createCompanyShift(data)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Organization Shift(s) Created!",
            color: "success",
          });
          this.companyID = res.data.id;
          this.nextStep2();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          if (err) {
            this.formErrorsStep2 = err.data;
          }
        });
    },
    addShift() {
      this.companyShiftDetails.shifts.push({});
      this.scrollToBottom();
    },
    removeShift(index) {
      this.companyShiftDetails.shifts.splice(index, 1);
    },
    nextStep2() {
      this.currentStep++;
    },
    previousStep() {
      this.companyShiftDetails = [{}];
      this.currentStep--;
    },
    // step 3 methods
    resetCompanyLocationForm() {
      this.$refs.companyFormStep3.reset();
      this.$refs.companyFormStep3.resetValidation();
    },
    setAutoComplete() {
      const interval = setInterval(async () => {
        if (this.$refs.address && window.google && window.google.maps) {
          clearInterval(interval);
          let input = document.getElementById("address");
          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          this.autocomplete.setComponentRestrictions({
            country: ["BA"],
          });
          this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();
            let lat = place.geometry.location.lat().toFixed(6);
            let lon = place.geometry.location.lng().toFixed(6);
            setTimeout(() => {
              this.companyLocationForm.address =
                place.name + ", " + place.formatted_address;
              this.coordinates.latitude = lat;
              this.coordinates.longitude = lon;
            }, 100);
          });
        }
      }, 100);
    },
    getCompanyShiftList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.company_shifts.getCompanyShiftList(params).then((res) => {
        this.shiftList = res.data.map((obj) => {
          return {
            name: obj.shift_name,
            value: obj.id,
          };
        });
      });
    },
    getCompanyAddressesList(params = {}) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.company_addresses
        .getCompanyAddressesList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.companyLocationList = res?.data;
          this.totalItems = res?.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    submitCompanyLocation() {
      let data = { ...this.companyLocationForm, coordinates: this.coordinates };
      this.$api.company_addresses
        .createCompanyAddresses(data)
        .then((res) => {
          bus.$emit("showToastMessage", {
            message: "Organization Address Created!",
            color: "success",
          });
          this.resetCompanyLocationForm();
          this.getCompanyAddressesList({ company_id: this.companyID });
        })
        .catch((err) => {
          this.formErrors = err.data;
          if (err.data.non_field_errors) {
            this.formErrors.non_field_errors = err.data.non_field_errors;
          }
        });
    },
  },
  mounted() {
    if (this.currentStep == 1) {
      this.getProjectsList();
    }
    if (this.currentStep == 3) {
      this.setAutoComplete();
      if (this.companyID && this.companyID != null) {
        this.getCompanyShiftList({ company_id: this.companyID });
      }
    }
  },
  components: { AgGridVue },
};
</script>

<style lang="scss">
.square {
  height: 38.5px;
  width: 38.5px;
  background-color: white;
  border: 1.5px solid #8b8b8b;
  border-radius: 5px;
  font-size: 14px;
  color: #8b8b8b;
}
.squareTitle {
  height: 40px;
  width: 40px;
}

.v-stepper__step {
  padding: 2% 2% 2% 2%;
  margin-bottom: 1.5%;
}
// .v-stepper__step.v-stepper__step {
//   background-color: #d9f1ff;
// }
.v-stepper__step.v-stepper__step--active {
  background-color: white;
}
</style>